<template lang="pug">
div.view_container(v-bind:class="{view_container_small: isSmallScreen}")
  Header(v-on:is-small-screen="setIsSmallScreen")
  div.signin_container
    div.view_title {{ title }}
    button.google_signin(v-on:click="googleSignIn"  v-if="showGoogleSignIn") Sign In with Google
    div.input_container
      input(v-model="email" type="text" placeholder="Enter email" v-bind:class="{highlight: highlightField}")
    div.input_container
      input(v-model="password" type="password" placeholder="Enter password" v-on:keyup="keyEnterToSubmit")
    button.cta(v-on:click="signin" style="touch-action: manipulation" v-bind:class="{small_screen:isSmallScreen}") Sign In
    button.secondary(v-on:click="nextRoute('/signup')" v-bind:class="{small_screen:isSmallScreen}") Don't have an account?
    button.secondary(v-on:click="sendResetPassword" v-bind:class="{small_screen:isSmallScreen}") Forgot password?
</template>

<script>
/* global gtag */

import { SIGNIN_MUTATION, SEND_RESET_PASSWORD_EMAIL_MUTATION, SIGNIN_WITH_CODE_MUTATION } from '../graphql/mutations'
import config from '../appConfig'
import Header from '../components/Header'

export default {
  name: 'SignIn',
  props: {
    title: {
      type: String,
      default: "Sign In"
    },
    next: {
      type: String,
      default: null,
    }
  },
  components: {
    Header,
  },
  data() {
    return {
      email: null,
      password: null,
      isSmallScreen: config.isSmallScreen(),
      highlightField: false,
      showGoogleSignIn: process.env.VUE_APP_SHOW_GOOGLE_SIGNIN,
    }
  },
  apollo: {

  },
  methods: {
    signinWithCode: function(authCode) {
      this.loading = true
      this.$apollo.mutate({
        mutation: SIGNIN_WITH_CODE_MUTATION,
        variables: {
          code: authCode,
        }
      }).then((data) => {
        console.log(data)
        const user = data.data.signInWithCode.user
        const token = data.data.signInWithCode.token
        // const isNew = data.data.signInWithCode.isNew
        const errors = data.data.signInWithCode.errors

        if (token) {
          this.$store.commit('setToken',token)
        }
        if (user) {
          this.$store.dispatch('signIn',user)
        }
        if (token && user) {
          gtag('event','login')
          if (this.next) { // this is used so views that need login can redirect to signin and back again after success
            this.$router.push(this.next)
          } else if (this.$route.params.next) {
            this.$router.push(this.$route.params.next)
          } else {
            this.$router.push({name:"Home"})
          }
        }

        this.errorsToast(errors)
      }).catch((error) => {
        console.error(error)
        this.errorToast(error)
      })
    },
    googleSignIn: async function () {
      // const googleUser = await this.$gAuth.signIn()
      // console.log(googleUser)
      // window.user = googleUser
      const authCode = await this.$gAuth.getAuthCode()
      // console.log(authCode)
      this.signinWithCode(authCode)
      // The authCode that is being returned is the one-time code that you can send to your backend server, so that the server can exchange for its own access_token and refresh_token.
      // const userId = googleUser.getId()
      // const profile = googleUser.getBasicProfile()
      // const response = googleUser.getAuthResponse()
      // const token = response.access_token

    },
    nextRoute: function(route) {
      this.$router.push(route)
    },
    sendResetPassword: function() {
      if (!this.email) {
        this.$toast.warning("Please fill out the email field and we will send out a reset password email.")
        this.highlightField = true
        return
      }

      this.$apollo.mutate({
        mutation: SEND_RESET_PASSWORD_EMAIL_MUTATION,
        variables: {
          email: this.email,
        }
      }).then((data) => {
        console.log(data)
        const success = data.data.sendResetPasswordEmail.success
        const errors = data.data.sendResetPasswordEmail.errors

        if (success) {
          this.$toast.success('Check you email. A reset password email was sent to you. If you don\'t see it the email may be in your spam.',{timeout:7000})
        } else {
          this.errorsToast(errors)
        }
      }).catch((error) => {
        console.error(error)
        this.errorToast(error)
      })
    },
    signin: function() {
      if (!this.email) {
        this.$toast.error('Missing an email')
        return
      } else if (!this.password) {
        this.$toast.error('Missing a password')
        return
      }

      this.highlightField = false

      this.$apollo.mutate({
        mutation: SIGNIN_MUTATION,
        variables: {
          input: {
            email: this.email,
            password: this.password
          }
        }
      }).then((data) => {
        console.log(data)
        const user = data.data.signIn.user
        const token = data.data.signIn.token

        if (token) {
          this.$store.commit('setToken',token)
        }
        if (user) {
          this.$store.dispatch('signIn',user)
        }
        if (token && user) {
          gtag('event','login')
          if (this.next) { // this is used so views that need login can redirect to signin and back again after success
            this.$router.push(this.next)
          } else if (this.$route.params.next) {
            this.$router.push(this.$route.params.next)
          } else {
            this.$router.push({name:"Home"})
          }
        } else {
          this.$toast.error('Invalid email or password')
        }

        var errors = data.data.signIn.errors
        this.errorsToast(errors)
      }).catch((error) => {
        console.error(error)
        this.errorToast(error)
      })
    },
    keyEnterToSubmit: function (e) {
      if (e.keyCode === 13) {
        this.signin()
      }
    },
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    }
  },
  mounted: function () {
    // window.vue = this
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.signin_container {
  /* display: inline-block; */
  display:block;
  text-align: left;
}

.secondary {
  font-size: 18px;
  padding: 26px 44px;
  border-radius: 35px;
  border-radius: 35px;
}

button {
  margin: 20px 20px 0px 0px;
}
button.google_signin {
  margin: 0 0 20px 0;
}
.small_screen {
  margin: 26px 0px;
  width: 100%;
}

.highlight {
  background-color: #ffe7b6;
}
</style>
